import SessionStorageAuth from '@/common/services/SessionStorage.auth';

const SESSION_TTL = 60 * 1000 * 30; // 30mn
const WARNING_DELAY = 60 * 1000; // 1mn

const ScanbatUserSession = (() => {
  let service;
  let timeoutId = null;
  const subscribers = {};

  function getService() {
    if (!service) {
      service = this;

      return service;
    }

    return service;
  }

  function subscribe(warningCallback, endedCallback) {
    const subscriberId = crypto.randomUUID();
    subscribers[subscriberId] = {
      warningCallback,
      endedCallback,
    };

    return subscriberId;
  }

  function unsubscribe(subscriberId) {
    delete subscribers[subscriberId];
  }

  function onSessionEnded() {
    clearTimeout(timeoutId);
    Object.keys(subscribers).forEach((subscriberId) => {
      if (typeof subscribers[subscriberId].endedCallback === 'function') {
        subscribers[subscriberId].endedCallback();
      }
    });
    SessionStorageAuth.clearToken();
  }

  function onWarning() {
    clearTimeout(timeoutId);
    Object.keys(subscribers).forEach((subscriberId) => {
      if (typeof subscribers[subscriberId].warningCallback === 'function') {
        subscribers[subscriberId].warningCallback();
      }
    });
    timeoutId = setTimeout(() => onSessionEnded(), WARNING_DELAY);
  }

  function startSession() {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => onWarning(), SESSION_TTL - WARNING_DELAY);
  }

  return {
    getService,
    startSession,
    subscribe,
    unsubscribe,
  };
})();

export default ScanbatUserSession;
