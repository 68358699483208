function roleChecker(user) {
  if (user && user.functionality_accesses) {
    const ok = [];
    const ko = [];

    Object.entries(user.functionality_accesses).forEach((item) => {
      if (item[1]) {
        ok.push(item);
      } else {
        ko.push(item);
      }
    });

    /* eslint-disable no-console */
    console.table(ok);
    console.table(ko);
    /* eslint-enable no-console */
  }
}

export default roleChecker;
