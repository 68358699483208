import 'react-toastify/dist/ReactToastify.css';
import './Snack.scss';

import React from 'react';

import Button from '@/common/components/atoms/Button/Button';
import {
  SnackDefaultProps,
  SnackPropTypes,
} from '@/common/components/molecules/Snack/Snack.proptypes';

const Snack = ({ actionTextDisp, message, onAction, onUndo, undoTextDisp }) => (
  <div className="Toastify" data-qa-id="toastify">
    <div className="Toastify__toast--content">{message}</div>
    <div className="Toastify__button-container">
      {onAction && actionTextDisp.trim().length > 0 && (
        <Button
          buttonSize="small"
          className="Toastify__toast--button"
          context="action-do"
          fontSize="small"
          onClick={onAction}
          outline
          reversed
          variant="secondary"
        >
          {actionTextDisp}
        </Button>
      )}
      {onUndo && (
        <Button
          buttonSize="small"
          className="Toastify__toast--button"
          context="action-undo"
          fontSize="small"
          onClick={onUndo}
          outline
          reversed
          variant="secondary"
        >
          {undoTextDisp}
        </Button>
      )}
    </div>
  </div>
);

Snack.defaultProps = {
  ...SnackDefaultProps,
};

Snack.propTypes = {
  ...SnackPropTypes,
};

export default Snack;
