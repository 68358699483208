import { ENDPOINT_ROOT } from '@/common/constants';
import axiosAuth from '@/common/interceptors/axios.AuthInterceptor';

const getUserCurrentInfo = ({ queryKey: [endpoint] }) =>
  axiosAuth.get(`${ENDPOINT_ROOT}/${endpoint}`).then((res) => ({
    ...res.data,
    color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
  }));

export default getUserCurrentInfo;
