/**
 * Format a snake or kebab case to camel case
 *
 * @param {string} string
 * @returns {string}
 */
const formatSnakeToCamelCase = (string) =>
  string.replace(/([-_][a-z])/gi, ($1) =>
    $1.toUpperCase().replace('-', '').replace('_', ''),
  );

export default formatSnakeToCamelCase;
