// eslint-disable-next-line import/no-extraneous-dependencies
import { createServer, Response } from 'miragejs';

// Constants
import {
  ENDPOINT_CARTS,
  ENDPOINT_CARTS_UNIQUE,
  ENDPOINT_ITEMS,
  ENDPOINT_NODES_JOB_TREE,
  ENDPOINT_NOTIFICATIONS,
  ENDPOINT_ORDERS,
  ENDPOINT_PARTNERS,
  ENDPOINT_PRODUCTS,
  ENDPOINT_QUOTES,
  ENDPOINT_QUOTES_DRAFT,
  ENDPOINT_QUOTES_INSTALLER,
  ENDPOINT_ROLES,
  ENDPOINT_ROOT,
  ENDPOINT_WISHLISTS,
  ENDPOINT_WORKSITES,
} from '@/common/constants';

// Mocks
import * as cart from './mocks/carts/cart.json';
import * as cartUnique from './mocks/carts/cart-unique.json';
import * as jobs from './mocks/jobs/jobs.json';
import * as notifications from './mocks/notifications/notifications.json';
import * as order from './mocks/orders/order.json';
import * as orders from './mocks/orders/orders.json';
import * as partners from './mocks/partners/partners.json';
import * as product from './mocks/products/product.json';
import * as productDiff from './mocks/products/product-diff.json';
import * as draft from './mocks/quotes/draft.json';
import * as installer from './mocks/quotes/installer.json';
import * as quote from './mocks/quotes/quote.json';
import * as roles from './mocks/roles/roles.json';
import * as wishlist from './mocks/wishlists/wishlist.json';
import * as worksites from './mocks/worksites/worksites.json';

const makeServer = () =>
  createServer({
    routes() {
      this.urlPrefix = process.env.REACT_APP_API_BASE_URL;
      this.namespace = `${ENDPOINT_ROOT}/`;

      /*** GET ***/

      // Get cart with discount classes
      // Not ready now in real API
      // To use with user ZC17BIDONE
      this.get(`${ENDPOINT_CARTS}/:id`, cart.default);
      this.get(`${ENDPOINT_CARTS_UNIQUE}`, cartUnique.default);

      // Get notifications list
      this.get(ENDPOINT_NOTIFICATIONS, notifications.default);

      // Get roles list
      this.get(ENDPOINT_ROLES, roles.default);

      // Get orders data for the key has_been_updated
      this.get(ENDPOINT_ORDERS, orders.default);

      // get data for the order 20999, order history contents data
      this.get(`${ENDPOINT_ORDERS}/:id`, order.default);

      // Get worksites data in context of sorting action
      this.get(ENDPOINT_WORKSITES, worksites.default);

      // Get jobs list
      this.get(ENDPOINT_NODES_JOB_TREE, jobs.default);

      // Get quote
      this.get(`${ENDPOINT_QUOTES}/:id`, quote.default);
      // TEST 500 logged
      this.get(
        ENDPOINT_QUOTES,
        () =>
          new Response(
            500,
            { some: 'header' },
            { errors: ['name cannot be blank'] },
          ),
      );
      // TEST 500 public
      this.get(
        '/',
        () =>
          new Response(
            500,
            { some: 'header' },
            { errors: ['name cannot be blank'] },
          ),
      );

      // Get draft quote
      this.get(`${ENDPOINT_QUOTES_DRAFT}/:id`, draft.default);

      // Get installer quote
      this.get(`${ENDPOINT_QUOTES_INSTALLER}/:id`, installer.default);

      // Get product
      this.get(`${ENDPOINT_PRODUCTS}/:id`, product.default);

      // Get productDiff data in context of sorting action
      this.get(
        `${ENDPOINT_PRODUCTS}/${productDiff.default.id}`,
        productDiff.default,
      );

      // Get wishlist
      this.get(`${ENDPOINT_WISHLISTS}/:id`, wishlist.default);

      // Get partners
      this.get(ENDPOINT_PARTNERS, partners.default);

      /*** POST ***/

      // Post draft quote
      this.post(`${ENDPOINT_QUOTES_DRAFT}/:id`, draft.default);

      // Post items from cart to draft
      this.post(
        `${ENDPOINT_QUOTES_DRAFT}/:id/${ENDPOINT_ITEMS}`,
        draft.default,
      );

      // Post new role
      this.post(ENDPOINT_ROLES, (schema, request) => {
        const data = JSON.parse(request.requestBody);

        // eslint-disable-next-line no-console
        console.log(ENDPOINT_ROLES, data);
      });

      /*** PATCH ***/

      // Patch draft quote
      this.patch(`${ENDPOINT_QUOTES_DRAFT}/:id`, draft.default);

      /*** DELETE ***/

      // Delete draft quote
      this.delete(`${ENDPOINT_QUOTES_DRAFT}/:id`, {});

      this.passthrough(`${process.env.REACT_APP_API_BASE_URL}/**`);
    },
  });

export default makeServer;
