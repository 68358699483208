import { useQuery } from 'react-query';

import getUserCurrentInfo from '@/common/api/users/current/getUserCurrentInfo';
import { ENDPOINT_USERS_CURRENT_INFO } from '@/common/constants';

const useUserCurrentInfo = (isLoggedIn) =>
  useQuery([ENDPOINT_USERS_CURRENT_INFO], getUserCurrentInfo, {
    enabled: !!isLoggedIn,
  });

export default useUserCurrentInfo;
