import {
  COMPANY_KEY_PARAM,
  ENDPOINT_ROOT_LOGIN,
  WAREHOUSE_KEY_PARAM,
} from '@/common/constants';
import axiosPublic from '@/common/interceptors/axios.PublicInterceptor';

const postLogin = (data) => {
  const params = {
    ...data,
  };

  delete params.company;
  delete params.warehouse;

  const { company, warehouse } = data;

  let endpoint = ENDPOINT_ROOT_LOGIN;
  if (company && warehouse) {
    endpoint = `${endpoint}?${WAREHOUSE_KEY_PARAM}=${warehouse}&${COMPANY_KEY_PARAM}=${company}`;
  }

  return axiosPublic.post(endpoint, params);
};

export default postLogin;
