import chronoEntitiesFR from '@/chrono/i18n/translations/fr_FR/entities.json';
import chronoMessagesFR from '@/chrono/i18n/translations/fr_FR/messages.json';
import { CHRONO, EBAT, MATERIAUX } from '@/common/constants';
import ebatEntitiesFR from '@/ebat/i18n/translations/fr_FR/entities.json';
import ebatMessagesFR from '@/ebat/i18n/translations/fr_FR/messages.json';
import materiauxEntitiesFR from '@/materiaux/i18n/translations/fr_FR/entities.json';
import materiauxMessagesFR from '@/materiaux/i18n/translations/fr_FR/messages.json';

import commonEntitiesFR from './translations/fr_FR/entities.json';
import commonMessagesFR from './translations/fr_FR/messages.json';

const translationResources = {
  fr: {
    common: {
      entities: commonEntitiesFR,
      messages: commonMessagesFR,
    },
    [EBAT]: {
      entities: ebatEntitiesFR,
      messages: ebatMessagesFR,
    },
    [CHRONO]: {
      entities: chronoEntitiesFR,
      messages: chronoMessagesFR,
    },
    [MATERIAUX]: {
      entities: materiauxEntitiesFR,
      messages: materiauxMessagesFR,
    },
  },
  en: {
    common: {
      entities: '',
      messages: '',
    },
    [EBAT]: {
      entities: '',
      messages: '',
    },
    [CHRONO]: {
      entities: '',
      messages: '',
    },
    [MATERIAUX]: {
      entities: '',
      messages: '',
    },
  },
};

export default translationResources;
