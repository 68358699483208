import axios from 'axios';

import { ROUTE_SCANBAT, UNAUTHORIZED } from '@/common/constants';
import RefreshTokenSubscriber from '@/common/services/RefreshTokenSubscriber';
import ScanbatUserSession from '@/common/services/ScanbatUserSession';
import SessionStorageAuth from '@/common/services/SessionStorage.auth';

import { setHeaders } from './axios.PublicInterceptor';

const authService = SessionStorageAuth.getService();
const refreshTokenService = RefreshTokenSubscriber.getService();

const axiosAuth = axios.create();
axiosAuth.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const setHeadersAuthorization = (config) => {
  const axiosConfig = { ...config };

  const token = authService.getAccessToken();

  if (token) {
    axiosConfig.headers.Authorization = `Bearer ${token}`;
  }

  return axiosConfig;
};

export const refresh = (refreshToken) =>
  new Promise((resolve, reject) => {
    axiosAuth
      .post(
        '/api/token/refresh',
        {
          refresh_token: refreshToken,
        },
        {
          _retry: true,
        },
      )
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          authService.setToken(res.data.token, res.data.refresh_token);
        } else {
          reject();
        }
      })
      .then(resolve)
      .catch((error) => {
        reject(error);
      });
  });

export const interceptAuthRequests = (apiScope) =>
  axiosAuth.interceptors.request.use(
    (config) => setHeadersAuthorization(setHeaders(config, apiScope)),
    (error) => Promise.reject(error),
  );

export const interceptAuthResponses = (logout, addZergRush, removeZergRush) =>
  axiosAuth.interceptors.response.use(
    (response) => {
      const isScanbat = window.location.pathname.includes(ROUTE_SCANBAT);
      if (isScanbat && response?.config?.headers?.Authorization) {
        ScanbatUserSession.startSession();
      }
      if (
        response.headers['x-zerg-rush'] &&
        response.headers['x-zerg-rush'] === '0'
      ) {
        addZergRush();
      } else {
        removeZergRush();
      }
      return response;
    },
    (error) => {
      const status = error.response?.status;
      const method = error.config?.method;

      // Logout if API server is down
      if (
        status === 500 &&
        method === 'get' &&
        process.env.NODE_ENV !== 'development'
      ) {
        logout(null, true);
      }
      const originalRequest = error.config;
      const refreshToken = authService.getRefreshToken();
      if (
        status === UNAUTHORIZED &&
        refreshToken &&
        // eslint-disable-next-line no-underscore-dangle
        !error.config._retry
      ) {
        const isRefreshing = refreshTokenService.getIsRefreshing();
        if (!isRefreshing) {
          refreshTokenService.setIsRefreshing(true);
          refresh(refreshToken)
            .then(() => {
              refreshTokenService.setIsRefreshing(false);
              const token = authService.getAccessToken();
              if (token) {
                refreshTokenService.onRefreshed();
              }
              refreshTokenService.clearSubscribers();
            })
            .catch(() => {
              refreshTokenService.setIsRefreshing(false);
              refreshTokenService.clearSubscribers();
              logout();
              authService.clearToken();
            });
        }
        return new Promise((resolve) => {
          refreshTokenService.subscribeTokenRefresh(() => {
            resolve(axiosAuth(originalRequest));
          });
        });
      }

      return Promise.reject(error);
    },
  );

export default axiosAuth;
