import { useQuery } from 'react-query';

import getGeneralOptions from '@/common/api/general-options/getGeneralOptions';
import { ENDPOINT_GENERAL_OPTIONS } from '@/common/constants';

const useGeneralOptions = ({ isLoggedIn }) =>
  useQuery([ENDPOINT_GENERAL_OPTIONS], getGeneralOptions, {
    enabled: !!isLoggedIn,
  });

export default useGeneralOptions;
