import './wdyr';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './common/styles/styles.scss';
import './common/styles/libs/rsuite/index.css';

import i18n from 'i18next';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { CustomProvider } from 'rsuite';
import en from 'rsuite/locales/en_GB';

import { MATERIAUX, SESSION_STORAGE_MIRAGE } from './common/constants';
import { useConfig } from './common/contexts/ConfigContext';
import lazyWithCatch from './common/helpers/lazyWithCatch';
import fr from './common/i18n/libs/rsuite/fr_FR';
import CommonProviders from './common/providers';
import makeServer from './common/server';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const AppEbat = lazyWithCatch(() => import('./ebat/App'));
const AppMateriaux = lazyWithCatch(() => import('./materiaux/App'));

if (sessionStorage.getItem(SESSION_STORAGE_MIRAGE) !== null) {
  makeServer();
}
const locales = { fr, en };
const locale = locales[i18n.language];

const App = () => {
  const { env } = useConfig();

  switch (env) {
    case MATERIAUX:
      return <AppMateriaux />;
    default:
      return <AppEbat />;
  }
};

ReactDOM.render(
  <CommonProviders>
    <Suspense fallback="">
      <CustomProvider locale={locale}>
        <App />
      </CustomProvider>
    </Suspense>
  </CommonProviders>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
