import {
  SESSION_STORAGE_CURRENT_PATH,
  SESSION_STORAGE_PREV_PATH,
} from '@/common/constants';

const SessionStoragePath = (() => {
  let service;

  function getService() {
    if (!service) {
      service = this;

      return service;
    }

    return service;
  }

  function getPrevPath() {
    return sessionStorage.getItem(SESSION_STORAGE_PREV_PATH);
  }

  function getCurrentPath() {
    return sessionStorage.getItem(SESSION_STORAGE_CURRENT_PATH);
  }

  function setPath(currentPath, prevPath) {
    if (!prevPath.includes('undefined')) {
      sessionStorage.setItem(SESSION_STORAGE_PREV_PATH, prevPath);
    }
    sessionStorage.setItem(SESSION_STORAGE_CURRENT_PATH, currentPath);
  }

  return {
    getCurrentPath,
    getPrevPath,
    getService,
    setPath,
  };
})();

export default SessionStoragePath;
