import PropTypes from 'prop-types';

export const SnackDefaultProps = {
  actionTextDisp: '',
  onAction: null,
  onUndo: null,
  undoTextDisp: '',
};

export const SnackPropTypes = {
  actionTextDisp: (props, propName) => {
    const { onAction, [propName]: actionTextDisp } = props;

    if (!onAction && actionTextDisp.trim().length > 0) {
      return new Error(
        'If we do NOT pass Action as props in Snackbar component we should NOT have actionTextDisp.',
      );
    }

    if (onAction && actionTextDisp.trim().length === 0) {
      return new Error(
        'If you pass an Action to Snackbar component we should have a valid actionTextDisp.',
      );
    }

    return null;
  },
  message: PropTypes.string.isRequired,
  onAction: PropTypes.func,
  onUndo: PropTypes.func,
  undoTextDisp: PropTypes.string,
};
