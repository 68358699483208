import {
  format as formatDate,
  formatDistance,
  formatRelative,
  fromUnixTime,
  isDate,
} from 'date-fns';
import { enGB as en, fr } from 'date-fns/locale';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ICU from 'i18next-icu';
import { initReactI18next } from 'react-i18next';

import translationResources from './resources';

const locales = { fr, en };

i18n
  .use(ICU)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'fr',
    fallbackLng: 'fr',
    supportedLngs: ['fr', 'en'],
    interpolation: {
      escapeValue: false,
      format: (value, format = 'short') => {
        let date;
        const locale = locales[i18n.language];

        if (typeof value === 'object') {
          date = value;
        } else if (!Number.isNaN(parseInt(value, 10))) {
          date = fromUnixTime(value);
        }

        if (isDate(date)) {
          // TODO: Switch
          if (format === 'short') return formatDate(date, 'P', { locale });
          if (format === 'long') return formatDate(date, 'PPPP', { locale });
          if (format === 'relative') {
            return formatRelative(date, new Date(), { locale });
          }
          if (format === 'month') {
            return formatDate(date, 'MMMM yyyy', { locale });
          }
          if (format === 'ago') {
            return formatDistance(date, new Date(), {
              locale,
              addSuffix: true,
            });
          }
          if (format === 'time') return formatDate(date, 'p', { locale });

          return formatDate(date, format, { locale });
        }

        return value;
      },
    },
    react: {
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
    },
    resources: translationResources,
    // debug: true,
  });

export default i18n;
