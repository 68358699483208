/* eslint-disable react/jsx-props-no-spreading */
import './Button.scss';

import PropTypes from 'prop-types';
import React, { forwardRef, useMemo } from 'react';

const Button = forwardRef(
  (
    {
      buttonSize,
      children,
      className,
      context,
      disabled,
      display,
      fontSize,
      href,
      id,
      multiline,
      onBlur,
      onClick,
      onFocus,
      onKeyPress,
      onKeyDown,
      outline,
      radius,
      rel,
      reversed,
      shadow,
      tabIndex,
      tag: Tag,
      target,
      textAlign,
      title,
      type,
      uppercase,
      variant,
      value,
      withIcon,
    },
    ref,
  ) => {
    const classNames = useMemo(
      () =>
        [
          'button',
          buttonSize ? `button--button-size-${buttonSize}` : null,
          display ? `button--display-${display}` : null,
          disabled ? 'button--disabled' : null,
          fontSize ? `button--font-size-${fontSize}` : null,
          multiline ? 'button--multiline' : null,
          outline ? 'button--outline' : null,
          radius ? 'button--radius' : null,
          reversed ? 'button--reversed' : null,
          shadow ? 'button--shadow' : null,
          textAlign ? `button--text-align-${textAlign}` : null,
          uppercase ? 'button--uppercase' : null,
          variant ? `button--variant-${variant}` : null,
          withIcon ? 'button--with-icon' : null,
          className,
        ]
          .filter((val) => val)
          .join(' '),
      [
        buttonSize,
        display,
        disabled,
        fontSize,
        multiline,
        outline,
        radius,
        reversed,
        shadow,
        textAlign,
        uppercase,
        variant,
        withIcon,
        className,
      ],
    );

    const conditionalProps = useMemo(
      () => ({
        ...(href ? { href } : {}),
        ...(rel ? { rel } : {}),
        ...(target ? { target } : {}),
        ...(id ? { id } : {}),
        ...{
          'data-qa-id': `${context || Tag}-${disabled ? 'disabled' : 'active'}`,
        },
        ...(value ? { 'data-qa-value': value } : {}),
      }),
      [Tag, context, disabled, href, id, rel, target, value],
    );

    return (
      <Tag
        ref={ref}
        aria-disabled={disabled}
        className={classNames}
        disabled={disabled}
        onBlur={onBlur}
        onClick={onClick}
        onFocus={onFocus}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        role="button"
        tabIndex={tabIndex}
        title={title}
        type={type}
        value={value}
        {...conditionalProps}
      >
        {children}
      </Tag>
    );
  },
);

Button.propTypeList = {
  buttonSize: PropTypes.string,
  display: PropTypes.string,
  fontSize: PropTypes.string,
  textAlign: PropTypes.string,
  variant: PropTypes.string,
};

Button.defaultProps = {
  buttonSize: null,
  children: null,
  className: '',
  context: null,
  disabled: false,
  display: null,
  fontSize: null,
  href: null,
  id: '',
  multiline: false,
  onBlur: () => {},
  onClick: () => {},
  onFocus: () => {},
  onKeyPress: () => {},
  onKeyDown: () => {},
  outline: false,
  radius: false,
  rel: null,
  reversed: false,
  shadow: false,
  tabIndex: undefined,
  tag: 'button',
  target: null,
  textAlign: null,
  title: '',
  type: 'button',
  uppercase: false,
  value: '',
  variant: null,
  withIcon: false,
};

Button.propTypes = {
  buttonSize: PropTypes.oneOfType([
    Button.propTypeList.buttonSize,
    PropTypes.oneOf([null]),
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  context: PropTypes.string,
  display: PropTypes.oneOfType([
    Button.propTypeList.display,
    PropTypes.oneOf([null]),
  ]),
  disabled: PropTypes.bool,
  fontSize: PropTypes.oneOfType([
    Button.propTypeList.fontSize,
    PropTypes.oneOf([null]),
  ]),
  href: PropTypes.string,
  id: PropTypes.string,
  multiline: PropTypes.bool,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyDown: PropTypes.func,
  outline: PropTypes.bool,
  radius: PropTypes.bool,
  rel: PropTypes.string,
  reversed: PropTypes.bool,
  shadow: PropTypes.bool,
  tabIndex: PropTypes.number,
  tag: PropTypes.oneOf(['a', 'button', 'submit']),
  target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top']),
  textAlign: PropTypes.oneOfType([
    Button.propTypeList.textAlign,
    PropTypes.oneOf([null]),
  ]),
  title: PropTypes.string,
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
  uppercase: PropTypes.bool,
  value: PropTypes.string,
  variant: PropTypes.oneOfType([
    Button.propTypeList.variant,
    PropTypes.oneOf([null]),
  ]),
  withIcon: PropTypes.bool,
};

export default Button;
