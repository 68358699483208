const RefreshTokenSubscriber = (() => {
  let service;
  let isRefreshing = false;
  let refreshSubscribers = [];

  function getService() {
    if (!service) {
      service = this;

      return service;
    }

    return service;
  }

  function subscribeTokenRefresh(callback) {
    refreshSubscribers.push(callback);
  }

  function clearSubscribers() {
    refreshSubscribers = [];
  }

  function onRefreshed() {
    refreshSubscribers.map((callback) => callback());
  }

  function getIsRefreshing() {
    return isRefreshing;
  }

  function setIsRefreshing(value) {
    isRefreshing = value;
  }

  return {
    getService,
    setIsRefreshing,
    getIsRefreshing,
    subscribeTokenRefresh,
    clearSubscribers,
    onRefreshed,
  };
})();

export default RefreshTokenSubscriber;
