import {
  SESSION_STORAGE_ACCESS_TOKEN,
  SESSION_STORAGE_REFRESH_TOKEN,
  SESSION_STORAGE_ZERG_RUSH,
} from '@/common/constants';

const SessionStorageAuth = (() => {
  let service;

  function getService() {
    if (!service) {
      service = this;

      return service;
    }

    return service;
  }

  function setToken(accessToken, refreshToken) {
    sessionStorage.setItem(SESSION_STORAGE_ACCESS_TOKEN, accessToken);
    sessionStorage.setItem(SESSION_STORAGE_REFRESH_TOKEN, refreshToken);
  }

  function getAccessToken() {
    return sessionStorage.getItem(SESSION_STORAGE_ACCESS_TOKEN);
  }

  function getRefreshToken() {
    return sessionStorage.getItem(SESSION_STORAGE_REFRESH_TOKEN);
  }

  function clearToken() {
    sessionStorage.removeItem(SESSION_STORAGE_ACCESS_TOKEN);
    sessionStorage.removeItem(SESSION_STORAGE_REFRESH_TOKEN);
  }

  function setZergRush(zergRush) {
    sessionStorage.setItem(SESSION_STORAGE_ZERG_RUSH, zergRush);
  }

  function getZergRush() {
    return sessionStorage.getItem(SESSION_STORAGE_ZERG_RUSH) === '0';
  }

  return {
    clearToken,
    getAccessToken,
    getRefreshToken,
    getZergRush,
    getService,
    setToken,
    setZergRush,
  };
})();

export default SessionStorageAuth;
