/* eslint-disable */
(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    define([], factory);
  } else if (typeof module === 'object' && module.exports) {
    module.exports = factory();
  } else {
    root.im = factory();
  }
})(this, function () {
  let element = document.body;
  let autoUpdate = true;
  let breakpoints = false;

  function setElement(newElement) {
    element = newElement;
  }

  function setUpdateMode(updateMode) {
    if (updateMode === 'manual') {
      autoUpdate = false;
      readBreakpoints();
    }
  }

  function readBreakpoints() {
    if (
      window.getComputedStyle &&
      window.getComputedStyle(element, '::after').content !== ''
    ) {
      const data = window.getComputedStyle(element, '::after').content;

      try {
        breakpoints = JSON.parse(removeQuotes(data));
      } catch (err) {
        breakpoints = false;
      }
    } else {
      breakpoints = false;
    }
  }

  function isBreakpointActive(breakpoint) {
    if (autoUpdate) {
      readBreakpoints();
    }

    return (
      breakpoints.hasOwnProperty(breakpoint) && breakpoints[breakpoint].active
    );
  }

  function isBreakpointNotActive(breakpoint) {
    return !isBreakpointActive(breakpoint);
  }

  function getActiveBreakpoint() {
    if (autoUpdate) {
      readBreakpoints();
    }

    let largest = { name: false, value: 0 };

    for (let breakpoint in breakpoints) {
      if (breakpoints.hasOwnProperty(breakpoint)) {
        const breakpointValue = parseFloat(breakpoints[breakpoint].value);

        if (breakpoints[breakpoint].active && breakpointValue > largest.value) {
          largest = { name: breakpoint, value: breakpointValue };
        }
      }
    }

    return largest.name;
  }

  function getBreakpointValue(breakpoint, asNumber) {
    if (autoUpdate) {
      readBreakpoints();
    }

    if (!breakpoints || !breakpoints.hasOwnProperty(breakpoint)) {
      return false;
    }

    return asNumber
      ? parseFloat(breakpoints[breakpoint].value)
      : breakpoints[breakpoint].value;
  }

  /**
   *
   * Function by Les James (@lesjames) taken from https://css-tricks.com/making-sass-talk-to-javascript-with-json/
   *
   **/
  function removeQuotes(string) {
    if (typeof string === 'string' || string instanceof String) {
      string = string
        .replace(/[']/g, '"')
        .replace(/\\|^[\s\S]{0,1}|[\s\S]$/g, '');
    }

    return string;
  }

  function getAll() {
    readBreakpoints();
    return breakpoints;
  }

  return {
    setElement: setElement,
    setUpdateMode: setUpdateMode,
    greaterThan: isBreakpointActive,
    lessThan: isBreakpointNotActive,
    getActive: getActiveBreakpoint,
    getValue: getBreakpointValue,
    update: readBreakpoints,
    getAll,
  };
});
