// Libs
import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';

// Constants
import {
  API_SCOPES,
  CHRONO,
  EBAT,
  ENV_LIST,
  MATERIAUX,
} from '@/common/constants';

const getEnvByHost = () => {
  switch (window.location.host) {
    case process.env.REACT_APP_HOST_EBAT:
      return EBAT;
    case process.env.REACT_APP_HOST_CHRONO:
      return CHRONO;
    case process.env.REACT_APP_HOST_MATERIAUX:
      return MATERIAUX;
    default:
      return null;
  }
};

const getEnvVars = (defaultEnv) =>
  (({ env }) => ({
    env,
    isEbat: env === EBAT,
    isChrono: env === CHRONO,
    isMateriaux: env === MATERIAUX,
    apiScope: API_SCOPES[env],
    isDev: process.env.NODE_ENV === 'development',
  }))({ env: getEnvByHost() || defaultEnv });

const ConfigContext = createContext(getEnvVars());

export const useConfig = () => useContext(ConfigContext);

export const ConfigContextProvider = ({ children, defaultEnv }) => (
  <ConfigContext.Provider value={getEnvVars(defaultEnv)}>
    {children}
  </ConfigContext.Provider>
);

ConfigContextProvider.defaultProps = {
  defaultEnv: null,
};

ConfigContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  defaultEnv: PropTypes.oneOf(ENV_LIST),
};
