// eslint-disable-next-line import/no-extraneous-dependencies
import _extends from '@babel/runtime/helpers/esm/extends';
import fr from 'date-fns/locale/fr';

const Calendar = {
  sunday: 'Di',
  monday: 'Lu',
  tuesday: 'Ma',
  wednesday: 'Me',
  thursday: 'Je',
  friday: 'Ve',
  saturday: 'Sa',
  ok: 'OK',
  today: "Aujourd'hui",
  yesterday: 'Demain',
  hours: 'Heures',
  minutes: 'Minutes',
  seconds: 'Secondes',
  formattedMonthPattern: 'MMM yyyy',
  formattedDayPattern: 'dd MMM yyyy',
  dateLocale: fr,
};

export default {
  common: {
    loading: 'Chargement...',
    emptyMessage: 'Aucune donnée disponible',
  },
  Plaintext: {
    unfilled: 'Non rempli',
    notSelected: 'Non sélectionné',
    notUploaded: 'Non téléchargé',
  },
  Pagination: {
    more: 'Plus',
    prev: 'Précédent',
    next: 'Suivant',
    first: 'Premier',
    last: 'Dernier',
    limit: '{0} / page',
    total: 'Nombre total de lignes: {0}',
    skip: 'Aller à{0}',
  },
  Calendar,
  DatePicker: _extends({}, Calendar),
  DateRangePicker: _extends({}, Calendar, {
    last7Days: 'Les 7 derniers jours',
  }),
  Picker: {
    noResultsText: 'Aucun résultat trouvé',
    placeholder: 'Sélectionner',
    searchPlaceholder: 'Rechercher',
    checkAll: 'Tout',
  },
  InputPicker: {
    newItem: 'Nouvele élément',
    createOption: 'Créer l\'option "{0}"',
  },
  Uploader: {
    inited: 'Initial',
    progress: 'Téléchargement',
    error: 'Erreur',
    complete: 'Terminé',
    emptyFile: 'Vide',
    upload: 'Télécharger',
  },
  CloseButton: {
    closeLabel: 'Fermer',
  },
  Breadcrumb: {
    expandText: 'Afficher le chemin',
  },
  Toggle: {
    on: 'Ouvrir',
    off: 'Fermer',
  },
};
