// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';

import SessionStorageAuth from './SessionStorage.auth';

const SessionStorageUser = (() => {
  let service;
  const authService = SessionStorageAuth.getService();

  function getService() {
    if (!service) {
      service = this;

      return service;
    }

    return service;
  }

  function getUserDataFromToken() {
    const token = authService.getAccessToken();

    if (token) {
      return jwt_decode(token);
    }

    return null;
  }

  return {
    getService,
    getUserDataFromToken,
  };
})();

export default SessionStorageUser;
