import '@/common/i18n';

import PropTypes from 'prop-types';
import React, { StrictMode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import {
  CHRONO,
  EBAT,
  ENV_LIST,
  MAIN_BODY_ID,
  REACT_QUERY_CACHE_TIME,
  REACT_QUERY_STALE_TIME,
  TRUE_STRING,
} from '@/common/constants';
import {
  ConfigContextProvider,
  useConfig,
} from '@/common/contexts/ConfigContext';

import AuthProvider from './AuthProvider';

export const queryClient = new QueryClient({
  // Default options for React Query
  defaultOptions: {
    // Options for useQuery
    queries: {
      cacheTime: REACT_QUERY_CACHE_TIME,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: REACT_QUERY_STALE_TIME,
    }, // Options for useMutation
    mutations: {},
  },
});

const CommonProviders = ({ children, defaultEnv }) => {
  const { env } = useConfig();

  const applyCssClassName = () => {
    const mainBody = document.body;
    ENV_LIST.forEach((envItem) => mainBody.classList.remove(envItem));
    const envClassNames = [env || defaultEnv];
    if (envClassNames[0] === CHRONO) {
      envClassNames.push(EBAT);
    }
    envClassNames.forEach((envItem) => mainBody.classList.add(envItem));
    mainBody.id = MAIN_BODY_ID;
  };
  applyCssClassName();

  return (
    <StrictMode>
      <ConfigContextProvider defaultEnv={defaultEnv}>
        <QueryClientProvider client={queryClient}>
          <HelmetProvider>
            <AuthProvider>{children}</AuthProvider>
          </HelmetProvider>
          {process.env.REACT_APP_REACT_QUERY_DEV_TOOLS === TRUE_STRING ? (
            <ReactQueryDevtools initialIsOpen={false} />
          ) : null}
        </QueryClientProvider>
      </ConfigContextProvider>
    </StrictMode>
  );
};

CommonProviders.defaultProps = {
  defaultEnv: EBAT,
};

CommonProviders.propTypes = {
  defaultEnv: PropTypes.oneOf(ENV_LIST),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default CommonProviders;
